import React, {useContext} from 'react';
import Providers from '~src/components/Providers';
import {ContentView, PageTemplate, Modals} from '~src/components';
// import Calculation2Organism from '../../components/organisms/Calculation1Organism';
import {LocaleContext} from '~src/context';
import {IPage} from '~src/types';
import Seo from '~src/components/Seo';

interface ICalculation1Props extends IPage {}

const Calculation1OrganismLazy = React.lazy(() => import('~src/components/organisms/Calculation1Organism'));

const Calculation1: React.FC<ICalculation1Props> = ({location, navigate, path}) => {
  const isSSR = typeof window === 'undefined';

  const {
    pages: {calculation1},
  } = useContext(LocaleContext);
  return (
    <Providers>
      <Seo
        title={calculation1.meta.title}
        description={calculation1.meta.description}
        keywords={calculation1.meta.keywords}
        path={path}
      />
      <PageTemplate
        breadcrumbs={[...calculation1.breadcrumbs, calculation1.header1]}
        breadcrumbsSource={calculation1.breadcrumbsSource}
      >
        <ContentView>
          {/*<Calculation1Organism />*/}
          {!isSSR && (
            <React.Suspense fallback={<div />}>
              <Calculation1OrganismLazy />
            </React.Suspense>
          )}
        </ContentView>
      </PageTemplate>
      <Modals navigate={navigate} path={path} location={location} />
    </Providers>
  );
};

export default Calculation1;
